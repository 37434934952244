import React, { useCallback, useEffect, useState } from "react";
import { Alert, Box, Button, Link } from "@mui/material";
import { Delete, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import GridContainer from "component/common/GridContainer";
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import ConfirmDialog from "component/common/ConfirmDialog";
import Preloader from "component/common/Preloader";
import { deleteClient, getClient } from "helper/backend";
import { routes } from "helper/route";
import { showError, showSuccess, toSimpleError } from "helper/util";
import { perms, useAccess } from "context/access";
import AccessDenied from "page/Error/AccessDenied";
import { useNavigate, useParams } from "react-router-dom";
import Client from "model/client";
import { AppError } from "@type";
import Error from 'page/Error';
import SectionInfo from "./Partial/SectionInfo";
import SectionBilling from "./Partial/SectionBilling";
import { UNABLE_DELETE_CLIENT } from "helper/error";
import SectionUsers from "./Partial/SectionUsers";
import { useTranslation } from "react-i18next";

const Single = () => {

  const navigate = useNavigate();
  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  /**
  * Read params from the url
  * Also cast to the right data type
  */
  let { id } = useParams();
  const recordId = parseInt(id!);

  // the client record fetched from the backend
  const [client, setClient] = useState<Client | undefined>()
  // error encoutered while fetching the client (if any)
  const [clientError, setClientError] = useState<AppError | undefined>();
  // whether the loading of the client is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the delete confirmation is visible
  const [isDeleteConfOpen, setIsDeleteConfOpen] = useState(false);
  // whether the deletion of the client is in progress
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  /**
  * Fetches the client record from the backend
  */
  const refreshClient = useCallback(() => {
    setIsLoadInProgress(true)
    getClient(recordId)
      .then(response => {
        setClient(response.client);
      })
      .catch(ex => {
        setClientError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [recordId])

  /**
  * Deletes the client record in the backend
  */
  const removeClient = useCallback(() => {
    setIsDeleteInProgress(true);
    deleteClient(recordId)
      .then(_response => {
        showSuccess(t("clientHasBeenDeleted"));
        return navigate(routes.list_clients);
      })
      .catch(ex => {
        let errMessage = t("unableToDeleteClient");
        const err = toSimpleError(ex)
        switch (err.code) {
          case UNABLE_DELETE_CLIENT:
            errMessage = t('deleteClientErrorMessage');
            break;
        }
        showError(errMessage);
      })
      .finally(() => {
        setIsDeleteInProgress(false);
      });
  }, [navigate, recordId, t]);

  // This hook runs once on component mount
  useEffect(() => {
    refreshClient();
  }, [refreshClient]);

  return (
    <>
      {isGranted(perms.view_clients)
        &&
        <>
          {
            !!client
            &&
            <Box>
              <PageTitleBar breadcrumbs={breadcrumbs(client, t)}>
                {isGranted(perms.delete_clients) && <ProgressButton variant="contained" color="error" sx={{ mr: 1 }} startIcon={<Delete />} isBusy={isDeleteInProgress} onClick={() => setIsDeleteConfOpen(true)}>{t("deleteClient")}</ProgressButton>}
                <Button variant="contained" color="secondary" component={Link} href={routes.list_clients} startIcon={<KeyboardArrowLeft />}>{t("back")}</Button>
              </PageTitleBar>
              {!client.isOnboardingComplete && <Alert variant="filled" severity="warning" sx={{ mb: 2, backgroundColor: "#f4d07c" }}>{t("clientOnboardingStillInProgress")}</Alert>}
              {!!client.isSuspended && <Alert variant="filled" severity="error" sx={{ mb: 2, backgroundColor: "#eb6f6a" }}>{t("clientSuspendedDueTo")}</Alert>}
              <GridContainer>
                <Grid xs={12} md={6}>
                  <SectionInfo client={client} refreshHandler={refreshClient} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SectionBilling client={client} refreshHandler={refreshClient} isLoadInProgress={isLoadInProgress} />
                </Grid>
                <Grid xs={12}>
                  <SectionUsers clientId={client.id!} />
                </Grid>
              </GridContainer>
              <ConfirmDialog
                isOpen={isDeleteConfOpen}
                yesButton={t("delete")}
                onConfirm={() => {
                  setIsDeleteConfOpen(false);
                  removeClient();
                }}
                onCancel={() => {
                  setIsDeleteConfOpen(false);
                }}
              >{t("deleteClientConfirmation")} &quot;{client.companyName}&quot;?</ConfirmDialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !client && <Preloader container="content" />}
          {!!clientError && <Error error={clientError} title404={t("clientNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_clients) && <AccessDenied />}
    </>

  )
}

const breadcrumbs = (client: Client, t: Function) => [{
  text: t("clients"),
  url: routes.list_clients,
}, {
  text: client.companyName!,
}];

export default Single;