import React, { useState, FocusEvent, useCallback, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import PageTitleBar from "component/common/PageTitleBar";
import Preloader from 'component/common/Preloader';
import { perms, useAccess } from "context/access";
import Error from 'page/Error';
import AccessDenied from 'page/Error/AccessDenied';
import { useTranslation } from "react-i18next";
import { AppError } from "@type";
import EditableCard from "component/styled/EditableCard";
import { useFormik } from "formik";
import * as Yup from 'yup'
import PaymentPlan from "model/paymentPlan";
import { assignPaymentPlan, getMyPaymentPlans, getOwnOrganization } from "helper/backend";
import { showError, toSimpleError } from "helper/util";
import Organization from "model/organization";
import ProgressButton from "component/common/ProgressButton";
import { TaskAlt } from "@mui/icons-material";
import { formatCurrency } from "helper/currency";

const Single = () => {

  const { isGranted, isNotGranted } = useAccess();
  const { t } = useTranslation();

  // the organization record fetched from the backend
  const [organization, setOrganization] = useState<Organization>();
  // the payment plan record fetched from the backend
  const [paymentPlans, setPaymentPlans] = useState<PaymentPlan[] | undefined>()
  // error encoutered while fetching the payment plan (if any)
  const [paymentPlanError, setPaymentPlanerror] = useState<AppError | undefined>();
  // whether the confirmation is visible
  const [isConfOpen, setIsConfOpen] = useState(false);
  // whether the loading of the organization is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false)
  // whether the loading of the plan change is in progress
  const [isPlanChangeInProgress, setIsPlanChangeInProgress] = useState<boolean>(false)

  /**
  * These are the values loaded into the form as the component mounts
  */
  let formInitialValues = {
    paymentPlanId: 0,
    paymentPlanName: '',
    name: ''
  };

  /**
* Form validation rules
*/
  let validationSchema: any = {
    paymentPlanId: Yup.number(),
    paymentPlanName: Yup.string().trim(),
    name: Yup.string().trim().oneOf([Yup.ref('paymentPlanName')], t("paymentPlansDontMatch")).required(t("fieldIsRequired"))
  };

  /**
  * Form configuration
  */
  const { values, errors, setStatus, handleChange, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: _values => {
      handleSelectPlan();
    },
  });

  const handleSelectPlan = () => {
    setIsPlanChangeInProgress(true)
    assignPaymentPlan(values.paymentPlanId)
      .then(_response => {
        refreshOrganization()
        setIsConfOpen(false)
        setFieldValue("name", "")
      })
      .catch(_ex => {
        showError(t("unableToSelectPaymentPlan"));
      })
      .finally(() => {
        setIsPlanChangeInProgress(false)
      })
  }

  /**
  * Fetches the organization record from the backend
  */
  const refreshOrganization = useCallback(() => {
    getOwnOrganization()
      .then(response => {
        setOrganization(response.organization);
      })
      .catch(_ex => {
        showError("Unable to load organization");
      })
  }, [])

  /**
  * Fetches the payment plan record from the backend
  */
  const refreshPaymentPlans = useCallback(() => {
    setIsLoadInProgress(true)
    getMyPaymentPlans()
      .then(response => {
        setPaymentPlans(response.paymentPlans);
      })
      .catch(ex => {
        setPaymentPlanerror(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      })
  }, [])

  useEffect(() => {
    refreshPaymentPlans();
    refreshOrganization();
  }, [refreshPaymentPlans, refreshOrganization])

  /**
  * Event handler called whenever the user focuses a form text field
  */
  const onTextFieldFocused = (e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  };

  return (
    <>
      {isGranted(perms.view_my_payment_plans)
        &&
        <>
          {
            !!paymentPlans
            &&
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
              <PageTitleBar title={t("paymentPlans")} />
              <Box sx={{ display: "flex", gap: "34px", flexWrap: "wrap", marginTop: "50px" }}>
                {
                  paymentPlans.map((paymentPlan: PaymentPlan, idx: number) => {
                    return (
                      <EditableCard key={idx} sx={{ overflow: "initial", width: "350px", height: "480px", display: "flex", flexDirection: "column" }} >
                        {paymentPlan.id === organization?.paymentPlanId &&
                          <div className="ribbon ribbon-top-left"><span>{t("active")}</span></div>
                        }
                        <Box sx={{ backgroundColor: paymentPlan.id === organization?.paymentPlanId ? "#33AD93" : "#414d5f", mt: 3, textAlign: "center", padding: "16px", color: "#FFFFFF", fontSize: "17px", textTransform: "uppercase" }}>
                          {paymentPlan.name}
                        </Box>
                        <Box sx={{ textAlign: "center", fontSize: "32px", fontWeight: 500, mt: 3 }}>
                          {formatCurrency(paymentPlan.price, paymentPlan.currencySymbol, paymentPlan.currencyPosition, paymentPlan.currencyHasSpacing!)}
                          <Box sx={{ fontSize: "17px", fontWeight: 400 }}>
                            {t("perMonth")}
                          </Box>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", margin: "0 auto" }}>
                          <Box sx={{ textAlign: "center", fontSize: "16px", mt: 5, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                            <TaskAlt color={paymentPlan.id === organization?.paymentPlanId ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.tagLimit} {t("tags")}
                          </Box>
                          <Box sx={{ textAlign: "center", fontSize: "16px", mt: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                            <TaskAlt color={paymentPlan.id === organization?.paymentPlanId ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.clientLimit} {t("clientsPlan")}
                          </Box>
                          <Box sx={{ textAlign: "center", fontSize: "16px", mt: 2, display: "flex", alignItems: "center", justifyContent: "center", gap: "6px" }}>
                            <TaskAlt color={paymentPlan.id === organization?.paymentPlanId ? "primary" : "secondary"} /> {t("upto")} {paymentPlan.userLimit} {t("usersPlan")}
                          </Box>
                        </Box>
                        <Box sx={{ alignSelf: "center", mt: 5 }}>
                          {
                            paymentPlan.id === organization?.paymentPlanId
                              ?
                              <Typography color="primary" sx={{ fontWeight: 600, mt: 1, fontSize: "18px" }}>
                                {t("selected")}
                              </Typography>
                              :
                              <Button className='buttonAlwaysText' variant="contained" size="large" color="secondary" sx={{ fontSize: "16px", backgroundColor: "#414d5f" }} onClick={() => { setIsConfOpen(true); setFieldValue("paymentPlanName", paymentPlan.name); setFieldValue("paymentPlanId", paymentPlan.id) }}>{t("select")}</Button>
                          }
                        </Box>
                      </EditableCard>
                    )
                  })
                }
              </Box>
              <Dialog
                open={isConfOpen}
                onClose={() => setIsConfOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <form noValidate onSubmit={handleSubmit}>
                  <DialogTitle>{t("confirmPaymentChange")}</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t("confirmPaymentChangeText")}
                    </DialogContentText>
                    <Box sx={{ fontSize: "25px", display: "flex", justifyContent: "center", fontWeight: 600 }}>
                      {values.paymentPlanName}
                    </Box>
                    <TextField variant="outlined" name="name" onChange={handleChange} value={values.name} error={!!errors.name} helperText={errors.name} onFocus={onTextFieldFocused} label={t("typePlan")} sx={{ width: "100%", mt: 2 }} />
                  </DialogContent>
                  <DialogActions>
                    <ProgressButton isBusy={isPlanChangeInProgress} color="error" variant="contained" type="submit" sx={{ mr: 2 }}>{t("selectPlan")}</ProgressButton>
                    <Button variant="contained" color="dark" className='buttonAlwaysText' onClick={() => setIsConfOpen(false)} autoFocus>{t("cancel")}</Button>
                  </DialogActions>
                </form>
              </Dialog>
            </Box>
          }
          {/* Show the prealoder only on the first fetch */}
          {isLoadInProgress && !paymentPlans && <Preloader container="content" />}
          {!!paymentPlanError && <Error error={paymentPlanError} title404={t("paymentPlansNotFound")} />}
        </>
      }
      {isNotGranted(perms.view_my_payment_plans) && <AccessDenied />}
    </>
  )
}

export default Single;