import React, { ChangeEvent, FocusEvent, useCallback } from 'react';
import { Autocomplete, Card, CardContent, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { getYesNoOptions } from 'helper/util';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@type';


type Props = {
  values: any;
  errors: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  handleChange: (e: ChangeEvent<any>) => void;
};

const Filters = ({ values, errors, setFieldValue, setStatus, handleChange }: Props) => {

  const { t } = useTranslation();

  const getOnboardingOptions = (allLabel?: string): SelectOption[] => {
    const options = [];
    if (!!allLabel) {
      options.push({ label: allLabel, value: Number.MIN_SAFE_INTEGER });
    }
    options.push({ label: 'Complete', value: 1 });
    options.push({ label: 'Pending', value: 0 });
    return options;
  }

  /**
   * Event handler called whenever the user focuses a form text field
   */
  const onTextFieldFocused = useCallback((e: FocusEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[name as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  /**
   * Event handler called whenever the user focuses a form select field
   */
  const onSelectFieldFocused = useCallback((fieldName?: string) => {
    const formErrors = errors;
    // clear the error of the respective field
    delete formErrors[fieldName as keyof typeof formErrors];
    setStatus(formErrors);
  }, [errors, setStatus]);

  return <Card sx={{ mb: 2 }}>
    <CardContent>
      <form noValidate>
        <Grid container spacing={2}>
          <Grid xs={12} md={3}>
            <TextField name="companyName" label={t("name")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.companyName} error={!!errors.companyName} helperText={errors.companyName} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField name="contactFullName" label={t("contact")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactFullName} error={!!errors.contactFullName} helperText={errors.contactFullName} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField name="contactEmail" label={t("email")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.contactEmail} error={!!errors.contactEmail} helperText={errors.contactEmail} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <TextField name="billingCity" label={t("city")} variant="outlined" onChange={handleChange} onFocus={onTextFieldFocused} value={values.billingCity} error={!!errors.billingCity} helperText={errors.billingCity} sx={{ width: '100%' }} />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isActive', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isActive');
              }}
              value={getYesNoOptions(t("all")).find(option => option.value === values.isActive)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getYesNoOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("active")} error={!!errors.isActive} helperText={errors.isActive} />}
            />
          </Grid>
          <Grid xs={12} md={3}>
            <Autocomplete
              onChange={(_e, selectedOption) => {
                setFieldValue('isOnboardingComplete', selectedOption ? selectedOption.value : Number.MIN_SAFE_INTEGER);
              }}
              onFocus={_e => {
                onSelectFieldFocused('isOnboardingComplete');
              }}
              value={getOnboardingOptions(t("all")).find(option => option.value === values.isOnboardingComplete)}
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              disablePortal
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={getOnboardingOptions(t("all"))}
              renderInput={(params: any) => <TextField {...params} label={t("onboardingStatus")} error={!!errors.isOnboardingComplete} helperText={errors.isOnboardingComplete} />}
            />
          </Grid>
        </Grid>
      </form>
    </CardContent>
  </Card>
}

export default Filters;