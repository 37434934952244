import React from 'react';
import NotFound from 'page/Error/NotFound';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Authmiddleware from 'route/auth';
import PrivateLayout from 'component/layout/PrivateLayout';
import PublicLayout from 'component/layout/PublicLayout';
import routes from './route';
import 'asset/scss/ktsd.scss';
import Settings from 'page/Onboarding/Wizard/Settings';
import Finish from 'page/Onboarding/Wizard/Finish';
import Payment from 'page/Onboarding/Wizard/Payment';
import PaymentConfirmation from 'page/Onboarding/Wizard/PaymentConfirmation';
import { useOnboarding } from 'context/onboarding';
import { useAuth } from 'context/auth';
import AccessRole from 'model/accessRole';
import PaymentPlans from 'page/Onboarding/Wizard/PaymentPlans';
import Logout from 'page/Account/Logout';
import ClientSuspendedPopup from 'component/common/ClientSuspendedPopup';

const App = () => {

  const { isOnboardingComplete, isLoggedIn } = useOnboarding()
  const { authUser } = useAuth();

  // helper function to find if we are on an onboarding route
  const isOnboardingRoute = (url: string) => {
    if (url.includes("onboarding")) {
      return true;
    } else {
      return false;
    }
  }

  return <React.Fragment>
    <ClientSuspendedPopup />
    <Router>
      <Routes>
        {/* Used to redirect if onboarding is ready and the user still access onboarding routes */}
        {
          isOnboardingComplete && isOnboardingRoute(window.location.href) &&
          <Route path="*" element={<Navigate to="/" />} />
        }
        {/* Onboarding Routes */}
        {
          !isOnboardingComplete && isLoggedIn
          &&
          <>
            {authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION &&
              <>
                <Route path="/onboarding/settings" element={<Settings />} />
                <Route path="/onboarding/payment-plans" element={<PaymentPlans />} />
                <Route path="/onboarding/finish" element={<Finish />} />
              </>
            }
            <Route path="/onboarding/payment" element={<Payment />} />
            {authUser.accessRoleType === AccessRole.TYPE_ORGANIZATION ?
              <Route path="*" element={<Navigate to="/onboarding/settings" />} />
              :
              <>
                <Route path="/onboarding/payment-confirmation" element={<PaymentConfirmation />} />
                <Route path="*" element={<Navigate to="/onboarding/payment" />} />
              </>
            }
            <Route path="/logout" element={<Logout />} />
          </>
        }
        {/* App Routes */}
        {
          isOnboardingComplete
          &&
          <>
            {routes.map((route, idx) => {
              let { component: Component, layout: Layout, isPublic, isStrict, pageProps } = route;
              if (!Layout) {
                Layout = isPublic ? PublicLayout : PrivateLayout;
              }
              return <Route key={idx} path={route.path} element={<Authmiddleware isPublic={isPublic} isStrict={isStrict}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </Authmiddleware>} />
            })}
            <Route path="*" element={<NotFound />} />
          </>
        }
      </Routes>
    </Router>
  </React.Fragment>
}

export default App;
