import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Link } from "@mui/material";
import { Check, KeyboardArrowLeft } from "@mui/icons-material";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import PageTitleBar from "component/common/PageTitleBar";
import ProgressButton from "component/common/ProgressButton";
import GridContainer from "component/common/GridContainer";
import { createReader, getDevice } from "helper/backend";
import { ValidationException } from "helper/error";
import { route, routes } from "helper/route";
import { isErrType, showError, showSuccess, toSimpleError } from "helper/util";
import { perms, useAccess } from "context/access";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import AccessDenied from "page/Error/AccessDenied";
import { useTranslation } from "react-i18next";
import Device from "model/device";
import { AppError } from "@type";
import Error from 'page/Error';
import Preloader from "component/common/Preloader";
import FormCreateReader from "./Partial/Form/Form";
import Reader from "model/reader";

type Values = {
  name: string;
  ip: string;
  sensitivity?: number;
  power?: number;
  vendor: number | undefined;
  zone: number | undefined;
  antennas?: string;
  hasLocalControl: boolean;
  readerMode: number | string;
  estimatedPopulation: number | string;
  session: number | string;
  searchMode: number | string;
}

const New = () => {

  const { isGranted, isNotGranted } = useAccess();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const deviceId = parseInt(id!)

  // the device record fetched from the backend
  const [device, setDevice] = useState<Device>()
  // error encoutered while fetching the device (if any)
  const [deviceError, setDeviceError] = useState<AppError>();
  // whether the loading of the distribution unit is in progress
  const [isLoadInProgress, setIsLoadInProgress] = useState<boolean>(false);
  // whether the saving of the data is in progress
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  /**
  * These are the values loaded into the form as the component mounts
  */
  const formInitialValues: Values = {
    name: '',
    ip: '',
    sensitivity: Reader.SENSITIVITY_MIN_RANGE,
    power: Reader.POWER_MIN_RANGE,
    vendor: undefined,
    zone: undefined,
    antennas: "1,2,3,4",
    hasLocalControl: false,
    readerMode: "",
    estimatedPopulation: "",
    session: "",
    searchMode: ""
  };

  /**
  * Form validation rules
  */
  const validationSchema = {
    name: Yup.string().trim().required(t("fieldIsRequired")),
    vendor: Yup.number().required(t("fieldIsRequired")),
    zone: Yup.number().required(t("fieldIsRequired")),
    ip: Yup.string().trim().when('vendor', {
      is: (value: number) => value === Reader.VENDOR_IMPINJ,
      then: schema => schema.required(t("fieldIsRequired")),
    }),
    antennas: Yup.string().trim().when('vendor', {
      is: (value: number) => value === Reader.VENDOR_IMPINJ,
      then: schema => schema.required(t("fieldIsRequired")),
    }).required(t("fieldIsRequired")),
    hasLocalControl: Yup.bool(),
  };

  /**
  * Form configuration
  */
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formInitialValues,
    validationSchema: Yup.object(validationSchema),
    onSubmit: values => {
      // TSL Vendors Reader doesn't have antennas/sensitivity/power
      if (values.vendor === Reader.VENDOR_TSL) {
        delete values.antennas;
        delete values.sensitivity;
        delete values.power;
      }
      saveReader(values);
    },
  });

  /**
  * Event handler called whenever the user saves the form
  */
  const saveReader = (values: any) => {
    setIsSubmitInProgress(true);
    createReader(deviceId, values)
      .then(_response => {
        showSuccess(t("readerHasBeenSaved"));
        return navigate(!!device?.distributionUnitId ? route(routes.view_du_device, [device.distributionUnitId, device.id]) : route(routes.view_standalone_device, device?.id));
      })
      .catch(ex => {
        const err = toSimpleError(ex);
        showError(t("unableToSaveReader"));
        // check if this is a validation error reported by the backend
        if (isErrType(err, ValidationException)) {
          // add the errors to the respective fields
          for (const [name, message] of Object.entries(err.fields)) {
            formik.setFieldError(name, t(message));
          }
          return;
        }
      })
      .finally(() => {
        setIsSubmitInProgress(false);
      });
  };

  /**
  * Fetches from the backend the device
  */
  const fetchDevice = useCallback(() => {
    setIsLoadInProgress(true)
    getDevice(deviceId)
      .then(response => {
        setDevice(response.device);
      })
      .catch(ex => {
        setDeviceError(toSimpleError(ex));
      })
      .finally(() => {
        setIsLoadInProgress(false);
      });
  }, [deviceId]);

  // This hook runs on first component mount to save the distribution unit in the state
  useEffect(() => {
    fetchDevice()
  }, [fetchDevice]);

  return (
    <>
      {isGranted(perms.create_devices) &&
        <>
          {!!device &&
            <Box>
              <form noValidate onSubmit={formik.handleSubmit}>
                <PageTitleBar breadcrumbs={breadcrumbs(t, device)}>
                  <ProgressButton type="submit" variant="contained" color="primary" sx={{ mr: 1 }} isBusy={isSubmitInProgress} startIcon={<Check />}>{t("saveReader")}</ProgressButton>
                  <Button variant="contained" color="secondary" component={Link} href={!!device.distributionUnitId ? route(routes.view_du_device, [device.distributionUnitId, device.id]) : route(routes.view_standalone_device, device.id)} startIcon={<KeyboardArrowLeft />}>{t("cancel")}</Button>
                </PageTitleBar>
                <GridContainer>
                  <Grid xs={12} md={6} >
                    <Card>
                      <CardContent>
                        <FormCreateReader formik={formik} />
                      </CardContent>
                    </Card>
                  </Grid>
                </GridContainer>
              </form >
            </Box>
          }
          {isLoadInProgress && !device && <Preloader container="parent" />}
          {!!deviceError && <Error error={deviceError} title404={t("deviceNotFound")} />}

        </>
      }
      {isNotGranted(perms.create_devices) && <AccessDenied />}
    </>
  )
}

const breadcrumbs = (t: Function, device: Device) => {
  if (!!device.distributionUnitId) {
    return [{
      text: t("distributionUnits"),
      url: route(routes.list_distribution_units),
    },
    {
      text: device.distributionUnitName,
      url: route(routes.view_distirbution_unit, device.distributionUnitId),
    },
    {
      text: device.name,
      url: route(routes.view_du_device, [device.distributionUnitId, device.id]),
    },
    {
      text: t("newReader"),
    }];
  } else {
    return [{
      text: t("standaloneDevices"),
      url: route(routes.list_devices),
    },
    {
      text: device.name,
      url: route(routes.view_standalone_device, device.id),
    },
    {
      text: t("newReader"),
    }];
  }
}

export default New;