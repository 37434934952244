import React, { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close, ErrorOutline } from "@mui/icons-material";
import { useOnboarding } from "context/onboarding";

const ClientSuspendedPopup = () => {

  const { client } = useOnboarding();
  const { t } = useTranslation();

  const [isPopupOpen, setIsPopupOpen] = useState(!!client?.isSuspended);

  const handlePopupClose = () => setIsPopupOpen(false);

  return (
    <Dialog onClose={handlePopupClose} open={isPopupOpen}>
      <Box sx={{ p: 3 }}>
        <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", fontSize: "32px", textAlign: "center" }}>
          <ErrorOutline color="error" sx={{ fontSize: "74px !important" }} />
          {t("accessSuspended")}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handlePopupClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Typography sx={{ fontSize: "18px", textAlign: "center" }}>
            {t("duAccessInfo")}
          </Typography>
        </DialogContent>
      </Box>
    </Dialog>
  )
};

export default ClientSuspendedPopup;